<template>
  <div class="content">
    <h1 class="title">
      {{ sport.Title }}
    </h1>

    <p v-if="sport.international_federation">
      {{ sport.international_federation.title }}
      <a
        v-if="sport.international_federation.siteUrl"
        :href="sport.international_federation.siteUrl"
        target="_blank"
      >
        {{ sport.international_federation.siteUrl }}
      </a>
    </p>
    <p v-else>Федерация не установлена</p>

    <EditButtons
      :is-delete-disable="isDeleteDisabled"
      @edit="handleEdit"
      @remove="handleRemove"
    />

    <SportDisciplines v-if="sport.id" :sport="sport" />
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import SportForm from "./SportForm";
import { AppRoute } from "@/common/const/route";
import SportDisciplines from "./SportDisciplines";
import EditButtons from "@/common/components/AppEditButtons";
import {
  DELETE_BLOCKED,
  DELETE_ERROR,
  DELETE_SUCCESS,
  UNKNOWN_ERROR,
} from "@/common/const/message";

export default {
  name: "SportShow",
  components: { EditButtons, SportDisciplines },
  data() {
    return {
      sport: { Title: "", id: null },
      isShowForm: false,
      isDeleteDisabled: true,
    };
  },
  async mounted() {
    try {
      this.sport = await this.$api[Resource.SPORTS].getOne(
        this.$route.params.id
      );
      if (!this.sport) {
        await this.$router.push(AppRoute.NOT_FOUND);
        return;
      }
      this.$route.meta.breadcrumbs[1].title = this.sport.Title;
      document.title = this.sport.Title;
      this.isDeleteDisabled = !this.sport.createdBy;
    } catch (e) {
      this.$notifier.error(UNKNOWN_ERROR);
      await this.$router.go(-1);
    }
  },
  methods: {
    async handleEdit() {
      this.$buefy.modal.open({
        parent: this,
        component: SportForm,
        props: { sport: this.sport },
        events: { close: this.closeForm },
        hasModalCard: true,
      });
    },
    async handleRemove() {
      if (!this.sport.createdBy) {
        this.$notifier.error(DELETE_BLOCKED);
        return;
      }
      this.$buefy.dialog.confirm({
        title: "Удаление вида спорта",
        message: `"${this.sport.Title}" будет удален!!!`,
        cancelText: "Отмена",
        confirmText: "Удалить",
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.SPORTS].delete(this.sport.id);
            await this.$router.push(AppRoute.SPORTS);
            this.$notifier.success(DELETE_SUCCESS);
          } catch (e) {
            this.$notifier.error(e.response?.data?.message || DELETE_ERROR);
          }
        },
      });
    },
    async closeForm(isAdded = true) {
      if (isAdded) {
        this.sport = await this.$api[Resource.SPORTS].getOne(
          this.$route.params.id
        );
      }
    },
  },
};
</script>
