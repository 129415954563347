var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('section',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.sport.Title)+" - дисциплина")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"rules":_vm.nameRules,"name":"Название","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('b-field',{attrs:{"label":"Название","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"","name":"Пол","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('b-field',{attrs:{"label":"Пол","message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":"Выберите пол"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('option',{attrs:{"value":""}},[_vm._v("Нет")]),_vm._l((_vm.genders),function(genderItem){return _c('option',{key:genderItem,domProps:{"value":genderItem}},[_vm._v(" "+_vm._s(genderItem)+" ")])})],2)],1)}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('AppFormButtons',{attrs:{"is-show-reset":false,"is-submit-disabled":invalid},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":_vm.handleClose}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }