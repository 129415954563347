<template>
  <ValidationObserver
    v-slot="{ validate, invalid }"
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ sport.id ? "Редактирование вида спорта" : "Добавить вид спорта" }}
        </p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required|min:3"
          name="Название"
        >
          <b-field
            label="Название"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-input v-model="title" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules=""
          name="Международная федерация"
        >
          <b-field
            label="Международная федерация"
            :message="errors[0]"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <b-select
              v-model="internationalFederation.title"
              placeholder="Выберите федерацию"
            >
              <option v-if="internationalFederation.title" :value="null">
                Нет
              </option>
              <option
                v-for="federation in federations"
                :key="federation.id"
                :value="federation.title"
              >
                {{ federation.title }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <FormButtons
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Resource } from "@/common/const/common";
import { mapGetters } from "vuex";
import FormButtons from "@/common/components/AppFormButtons";

export default {
  name: "SportForm",

  components: {
    FormButtons,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    sport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      title: "",
      internationalFederation: null,
      federations: [],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      sportType: "auth/getUserSportType",
    }),
  },

  created() {
    this.title = this.sport.Title;
    this.internationalFederation = this.sport.international_federation
      ? this.sport.international_federation
      : { title: null, id: null };
  },

  async mounted() {
    this.federations = await this.$api[
      Resource.INTERNATIONAL_FEDERATIONS
    ].get();
  },

  methods: {
    async handleSubmit() {
      try {
        const federation = this.federations.find(
          (item) => item.title === this.internationalFederation.title
        );

        let sport = {
          Title: this.title,
          international_federation: federation ? federation.id : null,
          category_sport_type: this.sportType,
        };

        let message;

        if (this.sport.id) {
          await this.$api[Resource.SPORTS].put(sport, this.sport.id);
          message = "Вид спорта успешно обновлен";
        } else {
          await this.$api[Resource.SPORTS].post(sport);
          message = "Вид спорта успешно добавлен";
        }

        this.$notifier.success(message);
        this.$emit("close");
      } catch (e) {
        this.$notifier.error("Не удалось сохранить вид спорта");
      }
    },

    async handleClose() {
      this.$emit("close", false);
    },
  },
};
</script>
