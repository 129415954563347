<template>
  <ValidationObserver
    v-slot="{ validate, invalid }"
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <section class="modal-card-head">
        <p class="modal-card-title">{{ sport.Title }} - дисциплина</p>
      </section>

      <section class="modal-card-body">
        <ValidationProvider :rules="nameRules" name="Название" slim>
          <b-field
            slot-scope="{ errors, valid }"
            label="Название"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="name" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider rules="" name="Пол" slim>
          <b-field
            slot-scope="{ errors, valid }"
            label="Пол"
            :message="errors"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
          >
            <b-select v-model="gender" placeholder="Выберите пол">
              <option value="">Нет</option>
              <option
                v-for="genderItem in genders"
                :key="genderItem"
                :value="genderItem"
              >
                {{ genderItem }}
              </option>
            </b-select>
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { GENDERS, Resource } from "@/common/const/common";
import AppFormButtons from "@/common/components/AppFormButtons";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";
import { teamSports } from "@/modules/calendar/const";

export default {
  name: "SportDisciplineForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    AppFormButtons,
  },
  props: {
    sport: {
      type: Object,
      required: true,
    },
    discipline: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      gender: null,
      genders: GENDERS,
    };
  },
  computed: {
    nameRules() {
      return this.sport.isTeam ? "min:3" : "required|min:3";
    },
  },
  created() {
    this.name = this.discipline.name;
    this.gender = this.discipline.gender;
  },
  methods: {
    handleClose() {
      this.$emit("close", false);
    },
    async handleSubmit() {
      const discipline = {
        name: this.name,
        gender: this.gender,
        category_sport: this.sport.id,
      };

      try {
        if (this.discipline.id) {
          await this.$api[Resource.SPORT_DISCIPLINES].put(
            discipline,
            this.discipline.id
          );
        } else {
          await this.$api[Resource.SPORT_DISCIPLINES].post(discipline);
        }
        this.$notifier.success(SAVE_SUCCESS);
        this.$emit("close");
      } catch (e) {
        this.$notifier.error(e.response?.data?.message || SAVE_ERROR);
      }
    },
  },
};
</script>
