<template>
  <div>
    <h2 class="subtitle">Дисциплины</h2>

    <AddButton
      :is-icon="true"
      :title="'Добавить дисциплину'"
      @click="handleUpdate"
    />

    <b-table
      v-if="disciplines.length"
      class="column"
      :data="disciplines"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :mobile-cards="false"
    >
      <b-table-column v-slot="props" field="id" label="#">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Название" sortable>
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="gender" label="Пол" sortable>
        {{ props.row.gender || "" }}
      </b-table-column>
      <b-table-column v-if="isEditor" v-slot="props" label="" centered>
        <div class="buttons is-centered">
          <b-button
            type="is-success"
            icon-left="pencil"
            @click="handleUpdate(props.row)"
          />

          <b-button
            type="is-danger"
            icon-left="delete"
            @click="handleRemove(props.row)"
          />
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import { mapGetters } from "vuex";
import SportDisciplineForm from "./SportDisciplineForm";
import AddButton from "@/common/components/AddButton";
import { DELETE_ERROR, DELETE_SUCCESS } from "@/common/const/message";

export default {
  name: "SportDisciplinesTable",

  components: { AddButton },

  props: {
    sport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      disciplines: [],
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.disciplines = await this.$api[Resource.SPORT_DISCIPLINES].get(
        `find-by-sport/${this.sport.id}`
      );
      this.disciplines.sort(
        (a, b) =>
          a.name.localeCompare(b.name) || a.gender?.localeCompare(b.gender)
      );
    },

    async handleUpdate(entity) {
      const discipline = entity ? entity : { name: "", gender: null };
      this.$buefy.modal.open({
        parent: this,
        component: SportDisciplineForm,
        props: { discipline, sport: this.sport },
        events: { close: this.close },
        hasModalCard: true,
      });
    },

    async handleRemove(discipline) {
      const name = discipline.name ? `${discipline.name} ` : "";
      const gender = discipline.gender || "";
      this.$buefy.dialog.confirm({
        title: "Удаление дисциплины",
        message: `"${name}${gender}" будет удалена!!! Это действие затронет все записи в базе!!!`,
        cancelText: "Отмена",
        confirmText: "Удалить",
        hasIcon: true,
        type: "is-danger",
        onConfirm: async () => {
          try {
            await this.$api[Resource.SPORT_DISCIPLINES].delete(discipline.id);
            this.disciplines = this.disciplines.filter(
              (item) => item.id !== discipline.id
            );
            this.$notifier.success(DELETE_SUCCESS);
          } catch (e) {
            this.$notifier.error(e.response?.data?.message || DELETE_ERROR);
          }
        },
      });
    },

    async close(isCreated = true) {
      if (isCreated) {
        await this.loadData();
      }
    },
  },
};
</script>
