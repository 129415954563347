var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.sport.id ? "Редактирование вида спорта" : "Добавить вид спорта")+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"rules":"required|min:3","name":"Название"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Название","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"","name":"Международная федерация"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Международная федерация","message":errors[0],"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":"Выберите федерацию"},model:{value:(_vm.internationalFederation.title),callback:function ($$v) {_vm.$set(_vm.internationalFederation, "title", $$v)},expression:"internationalFederation.title"}},[(_vm.internationalFederation.title)?_c('option',{domProps:{"value":null}},[_vm._v(" Нет ")]):_vm._e(),_vm._l((_vm.federations),function(federation){return _c('option',{key:federation.id,domProps:{"value":federation.title}},[_vm._v(" "+_vm._s(federation.title)+" ")])})],2)],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('FormButtons',{attrs:{"is-show-reset":false,"is-submit-disabled":invalid},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":_vm.handleClose}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }